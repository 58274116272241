import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const today = new Date();
  return (
    <div className="footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-4 pb-3">
            <img height="36px" alt="KarFarm Logo" src="images/logo/logo.png" />
          </div>
          <div className="col-12 col-lg-4">
            <p>
              <FontAwesomeIcon icon={faEnvelope} size="s" className="mr-2" />
              contact@karfarm.com
            </p>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} size="s" className="mr-2" />
              888-849-0645
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="s" className="mr-2" />
              Silicon Valley, Seoul, Tokyo
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <p className="text-center">
              ©
              {today.getFullYear()}
              {' '}
              KarFarm Inc. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
